<template>
    <div class="ThreeDsSci">
        <el-tag
            effect="dark"
        >
            科研绘图
        </el-tag>
        <p class="p justify">
            科研可视化逐渐成为中高档次论文的必需品和有效补充。通过三维动画和图像设计技术手段，将复杂、抽象的科学原理转化为形象、直观的模型、动画等可视化载体呈现，同时辅以文字和旁白说明，最大程度帮助观众理解和内容传播。
        </p>
        <img
            class="img-info"
            src="@/assets/images/3ds/sci/sanwei-keyan.png"
        >
        <p class="p justify">
            科研绘图的目的是表达，而可视化表达的本质，是剥离无关和次要的细节，通过图形强化表达的核心要点与逻辑，适用于项目申请、成果展示、学术交流等诸多场景。
        </p>
    </div>
</template>

<script>
export default {
  name: 'ThreeDsSci',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '三维可视化解决方案 - 科研方向')
      }
}
</script>
<style lang="less" scoped>
.ThreeDsSci{
    margin-bottom:6.25rem;
    .img-info{
      width: 80%;
      margin: 0 auto;
      display: block;
    }
}
</style>
